import React, { useState, useEffect } from "react";
import SEO from "../components/SEO";
import { useIdleTimer } from "react-idle-timer";
import "../css/main.css";
import { graphql } from "gatsby";
import UpdateContentModal from "../components/updateModal";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import hand from "../images/hand.jpg";

const Home = ({ data }) => {
  const [showScreensaver, setShowScreensaver] = useState(false);
  const [clickedCategory, setClickedCategory] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [showCategories, setShowCategories] = useState(true);

  useEffect(() => {
    window.showModal = function () {
      setShowUpdateModal(true);
    };
  }, []);

  const updateContent = () => {
    setShowUpdateModal(false);
    if (window !== undefined) {
      window.location.reload();
    }
  };

  const mp4 = data.wp.pages.nodes[1].home.video.mp4.mediaItemUrl;
  const webm = data.wp.pages.nodes[1].home.video.webm.mediaItemUrl;
  const secondsOfInactivity =
    data.wp.pages.nodes[1].home.video.secondsOfInactivity;

  const handleOnIdle = event => {
    setAnimate(false);
    setShowScreensaver(true);
    setShowCategories(false);
  };

  const handleOnActive = event => {
    setAnimate(true);

    setTimeout(() => {
      setShowScreensaver(false);
      setShowCategories(true);
    }, 300);
  };

  useIdleTimer({
    // TODO
    timeout: secondsOfInactivity * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  const {
    title,
    content,
    logo: { mediaItemUrl },
  } = data.wp.pages.nodes[1].home.mainInfo;

  const skyCategories =
    data.wp.pages.nodes[1].home.listOfIcons.selectTheProperties;

  const { bgColor, textColor } = data.wp.pages.nodes[1].home.btns;

  return (
    <>
      <SEO title="Home" />
      {showScreensaver && (
        <video
          className={"screensaver " + (animate ? "animate-class" : "")}
          autoPlay
          muted
          loop
        >
          <source src={webm} type="video/webm" />
          <source src={mp4} type="video/mp4" />
        </video>
      )}

      <div className="container">
        <div className="window-overlay right"></div>
        <UpdateContentModal
          hide={() => setShowUpdateModal(false)}
          show={showUpdateModal}
          updateContent={updateContent}
        />
        <section className="first-c">
          <div className="logo">
            <img src={mediaItemUrl} alt="website-logo" />
          </div>
          <div className="titleCont" style={{textAlign:'center'}}>
            <h2 className="title">{title}</h2>
            <p className="description">{content}</p>
            <img src={hand} style={{maxWidth:50, marginTop:20, opacity:0.4}} />
          </div>
        </section>
        <section className="second-c">
          {showCategories && (
            <div className="cards-container">
              {skyCategories &&
                skyCategories.map(category => (
                  <AniLink
                    fade
                    to={`${category.slug}`}
                    className="card"
                    key={category.id}
                  >
                    <img src={category.properties.mainInfo.icon.mediaItemUrl} />
                    <p className="excerpt">
                      {category.properties.mainInfo.excerpt}
                    </p>
                    {category.properties.mainInfo.adv && (
                      <img style={{
                        maxWidth: '80px',
                        textAlign: 'center',
                        'margin': '0 auto'}}
                        src={category.properties.mainInfo.adv.mediaItemUrl}/>
                    )}
                  </AniLink>
                ))}
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export const query = graphql`
  {
    wp {
      pages {
        nodes {
          home {
            btns {
              bgColor
              textColor
            }
            mainInfo {
              content
              logo {
                mediaItemUrl
              }
              title
            }
            video {
              secondsOfInactivity
              mp4 {
                mediaItemUrl
              }
              webm {
                mediaItemUrl
              }
            }
            listOfIcons {
              selectTheProperties {
                ... on WP_Post {
                  id
                  properties {
                    mainInfo {
                      content
                      excerpt
                      adv {
                        mediaItemUrl
                      }
                      icon {
                        mediaItemUrl
                      }
                    }
                  }
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Home;
